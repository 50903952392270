// App.js
import React from 'react';
import HomePage from "./HomePage";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import PrivacyPage from "./PrivacyPage";
import AboutPage from "./AboutPage"
import BlogPage from "./BlogPage"
import Footer from "./Footer"
import Navbar from "./Navbar"
import ContactUsPage from "./ContactUsPage";
import NotFoundPage from "./NotFoundPage";
import DynamicBlogPost from "./DynamicBlogPost";
import MusicPage from "./MusicPage";
import ArtPrintsPage from "./ArtPrintsPage";
import BooksPage from "./BooksPage";


function App() {
    return (
       <Router>
           <div className={"App"}>
               <Navbar />
               <Routes>
                   <Route path={"/"} element={<HomePage />} />
                   <Route path={"about-Starfield-Stories"} element={<AboutPage />} />
                   <Route path={"privacy-policy"} element={<PrivacyPage />} />
                   <Route path={"contact-us"} element={<ContactUsPage />} />
                   <Route path={"404.html"} element={<NotFoundPage />} />
                   <Route path="/blog/*" element={<BlogPage />} />
                   <Route path="art-prints" element={<ArtPrintsPage />} />
                   <Route path="music" element={<MusicPage />} />
                   <Route path="books" element={<BooksPage />} />
                   <Route path="/blog/:id" element={<DynamicBlogPost />} />
                   <Route path="/blog/post/:urlkey" element={<DynamicBlogPost />} />

               </Routes>
           </div>
           <Footer />
       </Router>
    );
}

export default App;
