// ArtPrintsPage.js
import React from 'react';

const ArtPrintsPage = () => (
    <div className="content">
        <div className={"spacer"}></div>
        <div className="hero-img" >Image goes here </div>
        <h1>Art Prints</h1>
        <h2>It’s Museum StarField!</h2>
        <p>Well, not yet. But, you and your little ones will want to become collectors of cheery prints, after you see just how happy they will make your living atmosphere!</p>

        <p>Keep in mind, our quality art prints are perfect for gifts, whether it’s for a holiday, birthday or just a wonderful surprise for someone dear. And we want to be sure to provide you with a wide variety to choose from. So, with more books on the horizon, being readied for publishing, you’ll have plenty of vibrant prints of our lively characters to choose from!</p>

       <p>Start your StarField Stories Art collection today!</p>

        <p>“StarField Stories” Recessed Framed Print
        $76.99
        Add to Cart
        “Fuzzy McKenzie” Recessed Framed Print
        $76.99
        Add to Cart
        “Marvin in the Kooky Spooky House” – Recessed Framed Print
        $76.99
        Add to Cart
        Crusty Bigglebones – Recessed Framed Print
        $76.99
        Add to Cart
        The Girl Who Could Not Sneeze – Recessed Framed Print
        $76.99
        Add to Cart
        The Sweet Eaters – The Trio (Recessed Framed Art Print)
            $76.99</p>
                <p>&nbsp;</p>
         </div>
);

export default ArtPrintsPage;
