// MusicPage.js
import React from 'react';

const MusicPage = () => (
    <div className="content">
        <div className={"spacer"}></div>
        <div className="hero-img" >Image goes here </div>
        <h1>Music</h1>
        <p>
        The music of StarField Stories sets the atmosphere and scene of all our stories!
        We’ve got happy, wacky, whimsical, kooky and classic type tunes custom made just for you while you read our books… And they are all original too!
        </p>

        <p>Marvin in the Kooky Spooky House Audiobook & Music Album Bundle
        $10.99
        ADD TO CART
        Marvin in the Kooky Spooky House (Album)
            $7.99</p>

    </div>
);

export default MusicPage;
