// HeroSection.js
import React from 'react';
import hero from './img/StarField-Stories-Header-1-1500x650.jpg';
import thesweeteaters from './img/The-Sweet-Eaters-for-Home-Page-1024x768-1-1.png'
const HeroSection1 = () => (
    <div>
        <div className="content">
            <div className={"spacer"}></div>
            <h1>Publishing Interesting and Vivacious Children's Books!</h1>
        <img src={hero} className={"hero-img"} alt={"Starfield Stories characters"} />
        <img src={thesweeteaters} className={"hero-img"} alt={"The Sweet Eaters - We've Got Great Stories!"} />
        <p>One of the biggest gifts anyone can share is imagination.

            At Starfield Stories we want to share our colorfully imaginative stories with children everywhere!</p>
        </div></div>
        );
export default HeroSection1;