//blogPosts/blogPosts.js
import image2 from '../img/Reading, Egg Nog & Chocolate.jpg'

const blogPosts = [

    {
        id: 1,
        urlkey:'placeholder',
        title: 'Placeholder',
        author: 'Lord Toph',
        date: 'May 1, 2024',
        image: image2,
        alt: 'Placeholder',
        description: 'Placeholder',
        component: () => import('./BlogPost1'), // Assuming BlogPost1.js returns a default React component
    },

  ];

export default blogPosts;