import HeroSection1 from "./HeroSection1";
import React from "react";

function HomePage() {
    return (
        <div className="content">
                <HeroSection1 />
        </div>
    );
}

export default HomePage;
