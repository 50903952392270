// PublicationsPage.js
import React from 'react';
import hero from './img/StarField-Stories-Books-Collection-Image-1536x1209.png'

const BooksPage = () => (
    <div className="content">
            <div className="hero-img-books" ><img src={hero} alt={"Starfield Stories Books Collection"} /></div>
        <h1>Books</h1>
            <p>StarField Stories’ mission, in fact our passion is expressed to all our readers
            and listeners to inspire and empower them with our love for children’s literature. The vitality and life that
            a first read can have on a young mind is a gateway to fantastic realms of self-discovery
            and imagination.</p>
            <p>Stories create insight, ideas, emotion and education that can be spread
            infinitely. StarField Stories invites you with privilege to explore our books to
            help discover or enrich those aspects within yourself and share it with those around you!
            Here’s where you can find of our books. Picture books, short stories, rhymes, novellas, novels…
            If they’re not here already, here’s where you will be able to find them and even be able to read a little bit about them!</p>

            <p>Our friend Lola adores reading about Marvin ~ Even after Halloween!</p>

            <p>Hallie Claire says… “Find a nice spot, Relax & Read!”</p>



            <h2>“Fuzzy Mc Kenzie” by LORD TOPH ~ Audio Book Narrated by Ross Allen</h2>



            <p>Fuzzy McKenzie is a humorously delightful story about a lively little girl and how a mishap on a rainy day led to happiness that could chase the clouds away.</p>






            <h2>“Crusty Bigglebones” by LORD TOPH ~ Audio Book Narrated by Ross Allen</h2>

            <p>Sometimes the neatest things can come to be from someone you might think to be… not so neat. All throughout history, history gets redefined by the misfits and oddballs who want to do more than just “fit in.” Crusty Bigglebones is that type of kid. Life may not be a bowl of cherries in the beginning for Crusty, but it turns out to be delightfully fruitful for him in the end.</p>





            <h2>“Marvin in the Kooky Spooky House” by LORD TOPH ~ Audio Book Narrated by Ross Allen</h2>

            <p>Only a few days before Halloween, and young Marvin is already in for a big scare. So when the time is right, he strikes out late in the night, to visit that spooky house up there. You’re in for a treat when you read to find out how Marvin gets tricked out of being frightened, once he bravely enters the Kooky Spooky House!
            Illustration from LORD TOPH’s “Five Old Wives’ Tales” ~
            Available on Amazon</p>







            <h2>“Lou Says Who Says” by LORD TOPH ~ Audio Book Narrated by Ross Allen</h2>
        <p>Introducing our adorable little Lou. She’s one of the quirkiest little girls you’d ever meet or come to know! Lou’s got a different way of looking at things from top to bottom, inside out. From this to that, Lou sees things outside the box. To her, unusual is just as good as anything normal would be. So if anyone thinks differently, Lou says, “Who Says?!?”</p>






            <h2>“The Sweet Eaters” by LORD TOPH ~ Audio Book Narrated by Ross Allen</h2>
        <p>Excite your eyes and ignite your taste buds as you journey into the world of Silas, Sola, and Sam with this wonderful tale.  The Sweet Eaters warmly shows you the soft and innocent hearts of three jovial and rather rotund children, as they find bliss and lessons within their own indulgence.  From the traditional and colorful illustrations to the wonderfully written story, The Sweet Eaters reminds every reader young and old that the sweetest of treats should not always be the choice of what we eat.</p>




            <h2>“The Girl Who Could Not Sneeze” by LORD TOPH</h2>


            <p>What does it mean to sneeze? Sneezing means everything – especially to someone who cannot or simply doesn’t know how. On a quaint island, in the quiet village of Kimp Pu, a young girl named Icha, turns her affliction of not being able to sneeze into an interestingly experimental discovery for her friends, her Aunt Jin and even herself.</p>




            <h2>Penelope Pettleby’s Cones, Rings, Zap-Machines and other Soothsayer Gadgets by LORD TOPH</h2>


            <p>Follow the life, habits and gadgets of Penelope Pettleby and her amazingly helpful and sometimes troublesome cones, rings, zap machines and other soothsayer gadgets. Penelope is the go-to friend for almost any remedy. In every way, Penelope believes that a friend in need is truly a friend indeed.</p>

            <h2>“Funny Little Me”</h2>
        <p>The story of a young boy who makes up for his mishaps tenfold with happiness and heart. This story illustrates the day to day life of someone who can love himself for who he is, even if he is just a little bit funny.</p>


            <h2>“Best Friends”</h2>

            <p>One of the best things in life is having someone you can count on, talk to, trust in and just plain be silly with. Whether it be goofing around, lying in the warm, afternoon sun, sporting about or getting lots of work down… There is nothing quite like sharing these times with a best friend. They are always there for you, on that you can depend.</p>





            <p>Learning to read and write should be loads of fun. But first, let’s get the alphabet done! Let’s go along with Trudy Lee on a letter-laced tour from A to Z. Before you know it, we’ll be sounding out our syllables and vowels, reading spelling and writing full sentences in a short while.</p>


    </div>
);

export default BooksPage;
