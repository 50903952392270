// ContactUsPage.js
import React from 'react';

const ContactUsPage = () => (
    <div className={"content"}>
        <div className={"spacer"}></div>


        <div className="hero-img" >Image goes here </div>
            <h1>Contact Starfield Stories</h1>

        <h2><a href="mailto:starfieldstories@gmail.com">Send Email to starfieldstories@gmail.com</a></h2>
    </div>
        );

export default ContactUsPage;