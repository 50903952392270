// Other imports
import { Suspense } from 'react';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BlogList from './blogPosts/BlogList'; // Make sure this import is correct
import DynamicBlogPost from './DynamicBlogPost'; // Make sure this import is correct

const BlogPage = () => {
    return (
        // Suspense wraps Routes to catch any lazy loading issues
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route index element={<BlogList />} />
                <Route path=":urlkey" element={<DynamicBlogPost />} />
            </Routes>
        </Suspense>
    );
};

export default BlogPage;
