// AboutPage.js
import React from 'react';
import hero from './img/Mr.-Grumpinsky-616x1024.jpg'
import painting from './img/LORD-TOPH-Painting-Troll-150x150.jpg';
import Anna from './img/Anna-of-StarField-Stories-image-1-150x150.jpg'
const AboutPage = () => (
    <div className="contentsub">
        <div className="hero" ><img src={hero} className="hero" alt={"Mr. Grumpinsky"} />
        </div>
        <h1 className={'extra'}>About Starfield Stories</h1>

        <p>StarField Stories publishes inspirational and vivacious children’s books, created with a wide array of styles, stories and themes. Created by Monté CrisToph CEO, Lord Toph, StarField Stories is named after the home that he grew up in as a child. Rooted in the warmth of childhood memories, Lord Toph imbues readers with nostalgia, having such feeling and memories embedded in each and every word of the stories he creates.</p>

        <p>StarField Stories and its literature have been created to house resounding tales that capture the eyes and heart of every reader, young and old. We aim to have our books distributed to educational systems, such as schools, libraries and educational workshops for children. In addition, our goal is to appeal to select bookstores, reading centers and prominent venues in other forms of media (i.e., audio/visual presentations, film, radio broadcasts/podcast presentations, illustration touring, etc.).</p>

        <p>We invite you to explore and embrace our stories and become part of a new and very fruitful establishment of children’s literature.</p>
        <p>StarField Stories’ Editor-In-Chief, Mr. Peter Grumpinsky, shown here, has over forty years of experience in publishing. He worked his way up from an intern position in the mail room of the “The Pepperwinkle Post” and went on to become the newspaper’s leading reporter for twelve solid years. After his many successful years delivering the The Post’s leading stories, Grumpinsky aimed to expand his skills and take on a more challenging position. He then left The Pepperwinkle Post after he was offered a position as an assistant to the editor of the “Raddentacket Times.” After five stable years as an editor’s assistant, Grumpinsky became the junior editor for the newspaper. This was a position he carried out commendably for well over a decade. Although he was pleased to have held such a valuable position, his career working for the Raddentacket Times came to an unfortunate end due to the sudden illness of his pet parakeet, “Maximillian.” Once his bird recovered, Grumpinsky returned to the work field after he was offered the prestigious position as “Editor-In-Chief” for StarField Stories Publishing. Upon happily accepting the position at StarField, Grumpinsky commented proudly, “In my many years of employment, I have never been so pleased with what I am doing as I am now. StarField Stories has become my new grasp on life and nothing makes me happier than being an individual that is able to contribute to the positive growth of children.” We at StarField Stories are very honored to have Mr. Grumpinsky as an essential member of our staff.</p>
        <h2>How Each StarField Story is Created</h2>
        <p>The inspiration for our stories comes from an assortment of places.  No two children are exactly alike…not even identical twins!</p>

        <p>So keeping that in mind, I make sure to extract each story concept from the depths of imagination, from the realms of dreams and the portals of past and present.</p>

        <p>Combining forces of creativity with gifted storytellers and many of the fundamental principles I have received from formal training and ideas as far back as the earliest days of my upbringings, makes each and every story wonderfully unique in its own right.</p>

        <p><img src={painting} className={'herosmall'} alt={"Lord Toph painting a troll."} />
            <div className={"caption"}>INSET IMAGE: Lord Toph painting a troll. This little watercolor sketch actually inspired him to create the story, “Trolling Along.”</div></p>
        <p>As a former student of the Memphis Academy of Arts (now known as the Memphis College of Art), I still credit much of my creative diversity to the teachings of my former professors who instructed me there.  I went to this school thinking I was already an artist, but I was reshaped and completely reformed! By the time I left, I realized that there was a big difference in thinking you’re an artist and actually being an artist. Thinking “outside the box” and conceptualizing “outside the lines” has expanded my mindset as well as the realm of my creativity and growth over the years. I was always taught by my mother and father to strive to achieve the impossible and along the way I have come to realize that everything is possible. This is the mindset I aim to keep with StarField Stories, whether I am writing a story, illustrating for a story, composing the music to accompany it or narrating it.</p>

        <p>Children, like many adults, yearn for fresh invention… and in order to invent, one first has to imagine.</p>

        <p>All the while we are using this recipe to conceptualize ideas into tales… we are shedding a new light on the future, expanding our imaginations and encouraging children to do the same.</p>

        <p><img src={Anna} alt={"Anna the siberian cat"} className={"herosmall-right"}/></p><p>By the way ~ Our friend Anna just loves our books! FYI – Anna is one of LORD TOPH’s best friends and although Anna is a Siberian cat, she happens to be the actual cat which inspired the character “Anna P. Wildcat” in the adventurous novel, “Lox & Leonard” (Soon to be released)
        Being able to educate and inspire children, is a very important aspect of our cause. Our dream and foremost essential goal is to incorporate technical skills with artistry, in order to enrich the educational growth of children. I feel it is important to know how to apply technology, as long as you know how to preserve the human touch in the process. It always works well for us to rely on our own sense of discovery first, in order to embark upon new branches of originality.
        </p>
        <p><div className={"caption"}>INSET IMAGE: Anna the Siberian cat.</div></p>
                    <p>We at StarField Stories invite you to read our books and gain the experience for yourself!</p>

                    <p>– LORD TOPH</p>
            </div>

);


export default AboutPage;